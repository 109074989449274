.shopping_cart_page {
  margin: 50px 0 100px 0;
  .shopping_cart_content {
    display: grid;
    grid-template-columns: 1fr 600px;
    align-items: start;
    grid-gap: 50px;
    @include media-max(1480px) {
      grid-template-columns: 1fr 400px;
    }
    @include media-max(1200px) {
      grid-template-columns: 100%;
    }
    .cart_items {
      display: grid;
      .item {
        display: grid;
        grid-template-columns: 200px 1fr 200px;
        grid-gap: 30px;
        background: $color-white;
        border-radius: 0.428rem;
        box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
        margin-bottom: 20px;
        transition: all 0.3s ease-in-out;
        &:last-child {
          margin-bottom: 0;
        }
        &:hover {
          transform: translateY(-5px);
          box-shadow: 0 4px 25px 0 rgba(34, 41, 47, 0.25);
        }
        @include media-max(992px) {
          grid-template-columns: 150px 1fr 170px;
        }
        @include media-max(768px) {
          grid-template-columns: 100%;
          grid-gap: 30px;
          padding: 1.5rem 1rem;
        }
        .photo {
          @include full-size;
          padding: 1.5rem 1rem;
          @include media-max(768px) {
            height: 170px;
            @include flex-center;
            padding: 0;
          }
          img {
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
          }
        }
        .content {
          padding: 1.5rem 1rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          @include media-max(768px) {
            padding: 0;
          }
          .name {
            color: #5e5873;
            font-size: 1.2rem;
            font-weight: 500;
            @include media-max(992px) {
              font-size: 1rem;
            }
          }
          .quantity {
            @include flex-align-center;
            color: #6e6b7b;
            margin: 5px 0;
            @include media-max(768px) {
              margin: 10px 0;
            }
            label {
              margin-right: 10px;
            }
            .quantity_button {
              display: grid;
              grid-template-columns: 25px 35px 25px;
              align-items: center;
              border-radius: 4px;
              border: 1px solid #d8d6de;
              width: fit-content;
              padding: 5px 0;
              p {
                font-size: 1rem;
                text-align: center;
                cursor: pointer;
                &:nth-child(2) {
                  cursor: auto;
                  border-left: 1px solid #d8d6de;
                  border-right: 1px solid #d8d6de;
                }
              }
            }
          }
          .sku {
            font-size: 1rem;
            color: #b9b9c3;
          }
        }
        .buttons {
          @include flex-column-center;
          padding: 1.5rem 1rem;
          border-left: 1px solid rgba(60, 60, 60, 0.26);
          text-align: center;
          @include media-max(768px) {
            border-left: 0;
            padding: 0;
          }
          .price {
            font-size: 1.1rem;
            color: $color-primary;
            font-weight: bold;
            margin-bottom: 20px;
            @include media-max(992px) {
              font-size: 1rem;
            }
          }
          button {
            @include flex-center;
            width: 100%;
            height: 38px;
            background: #e3e3e3;
            color: #2a2e30;
            font-size: 13px;
            border-radius: 6px;
            font-weight: 500;
            @include hover {
              background: #ddd;
            }
            svg {
              width: 19px;
              height: 19px;
              font-size: 18px;
              margin-right: 5px;
            }
          }
        }
      }
    }
    .total_amount {
      background: $color-white;
      border-radius: 0.428rem;
      box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
      padding: 1.5rem 1rem;
      .total_price {
        @include flex-between;
        font-size: 20px;
        color: #6e6b7b;
        font-weight: 600;
        @include media-max(768px) {
          font-size: 16px;
        }
      }
      h6 {
        color: #b9b9c3;
        font-weight: 500;
        font-size: 14px;
        border-bottom: 1px solid #ebe9f1;
        padding-bottom: 10px;
        margin: 20px 0;
      }
      .buttons {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 20px;
        align-items: center;
        @include media-max(1480px) {
          grid-template-columns: 100%;
        }
        @include media-max(1200px) {
          grid-template-columns: repeat(3, 1fr);
        }
        @include media-max(768px) {
          grid-template-columns: 100%;
          grid-gap: 10px;
        }
        .c_button {
          @include flex-center;
          width: 100%;
          height: 38px;
          background: #e3e3e3;
          color: #2a2e30;
          font-size: 13px;
          border-radius: 6px;
          font-weight: 500;
          @include hover {
            background: #ddd;
          }
          svg {
            width: 18px;
            height: 18px;
            margin: 2px 1px 0 0;
          }
          &.primary {
            background: $color-primary;
            color: $color-white;
            svg {
              margin: 0 0 0 10px;
            }
          }
          &.outline {
            background: $color-white;
            @include hover {
              background: #ddd;
            }
          }
        }
      }
    }
  }
  .cart_is_empty {
    @include flex-center;
    text-align: center;
    font-size: 24px;
    margin: 100px 0;
    @include media-max(768px) {
      font-size: 17px;
    }
    svg {
      margin-right: 10px;
    }
  }
  .delivery-text {
    color: $color-white;
    font-size: 18px;
    margin-bottom: 20px;
    line-height: 18px;
    text-align: center;
    background-color: #0787ea;
    padding: 10px;
    text-transform: uppercase;

    span {
      font-weight: 700;
    }
  }
}

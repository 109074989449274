.cms_auth_page {
  width: 100%;
  min-height: 100vh;
  .container {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    max-width: 100%;
    width: 100%;
    min-width: 100%;
    background-color: #0a9ff0;
    .auth_form_holder {
      background-color: $color-white;
      width: 370px;
      border-radius: 20px;
      padding: 70px 30px;
      @include media-max(450px) {
        width: 90%;
        padding: 50px 20px;
      }
      box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
        rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
      .title {
        margin-bottom: 70px;
        @include media-max(450px) {
          margin-bottom: 40px;
        }
        h2 {
          font-weight: bold;
          font-size: 34px;
          @include media-max(450px) {
            font-size: 26px;
          }
        }
      }
      form {
        @include flex-column-center;
        width: 100%;
        .input_holder {
          width: 100%;
          margin-bottom: 30px;
          .form_field {
            input {
              border-radius: 30px;
              &:focus {
                border-color: #0a9ff0;
              }
              &:focus + label {
                color: #0a9ff0;
              }
            }
          }
        }
        .error_msg {
          margin-top: 10px;
        }
        button {
          margin-top: 50px;
          border-radius: 30px;
          color: $color-white;
          font-weight: 500;
          font-size: 18px;
          background: linear-gradient(to right, #0077e6, #0a9ff0);
          width: 60%;
          height: 40px;
          box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
          @include hover {
            opacity: 0.7;
          }
          @include media-max(450px) {
            margin-top: 30px;
          }
        }
      }
    }
  }
}
.google_icon {
  button {
    background-color: #cf4332 !important;
    color: white !important;
    font-size: large !important;
    div {
      margin-right: 0 !important;
      background-color: #cf4332 !important;
      border-right: 1px solid white;
    }
    span {
      padding: 0 40px !important;
    }
  }
}

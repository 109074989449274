.cms_page {
  form.form_holder {
    .form_group {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 30px;
      margin: 20px 0;
      @include media-max(1000px) {
        grid-template-columns: 100%;
        grid-gap: 40px 0;
      }
    }
    .dropzone_wrapper {
      width: 100%;
      label {
        font-size: 0.8em;
        color: rgba($color: $color-black, $alpha: 0.4);
      }
      &.main_photo {
        .photo {
          border-radius: 4px;
          @include inline-bg;
          position: relative;
          height: 250px;
          @include media-max(1600px) {
            height: 200px;
          }
          svg {
            position: absolute;
            top: 5px;
            right: 5px;
            color: $color-danger;
            font-size: $size-icon-sm;
            cursor: pointer;
            font-size: 30px;
            @include media-max(1600px) {
              font-size: 24px;
            }
          }
        }
      }
      .input_file_holder {
        height: 100px;
        border: 1px dashed rgba($color: $color-black, $alpha: 0.4);
        position: relative;
        cursor: pointer;
        margin: 20px 0;
        input {
          @include absolute-full;
          opacity: 0;
          z-index: 1;
          cursor: pointer;
        }
        span {
          @include absolute-full;
          @include flex-center;
          font-size: 18px;
          cursor: pointer;
          color: rgba($color: $color-black, $alpha: 0.4);
        }
      }
      .photos_grid {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 20px;
        @include media-max(500px) {
          grid-template-columns: 100%;
        }
        .photo {
          border-radius: 4px;
          @include inline-bg;
          height: 250px;
          position: relative;
          @include media-max(1600px) {
            height: 200px;
          }
          @include media-max(500px) {
            height: 170px;
          }
          svg {
            position: absolute;
            top: 5px;
            right: 5px;
            color: $color-danger;
            font-size: $size-icon-sm;
            cursor: pointer;
            font-size: 30px;
            @include media-max(1600px) {
              font-size: 24px;
            }
          }
        }
      }
    }
    .upload_photos_row {
      margin-top: 20px;
      display: grid;
      grid-template-columns: 25% calc(75% - 20px);
      grid-gap: 0 20px;
      @include media-max(1000px) {
        grid-template-columns: 100%;
        grid-gap: 20px 0;
      }
    }
    .form_buttons_group {
      @include flex-align-center;
      justify-content: flex-end;
      .mh_20 {
        margin: 0 20px;
      }
      &.three_buttons {
        @include media-max(500px) {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 15px;
          button {
            margin: 0 !important;
          }
        }
      }
    }
    .declaration_title {
      margin: 50px 0 40px 0;
      padding-bottom: 10px;
      border-bottom: 1px solid #ddd;
      color: #5a5a5a;
      font-weight: 500;
    }
    .x3_row {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 20px;
      margin-bottom: 50px;
      @include media-max(1000px) {
        grid-template-columns: 100%;
        grid-gap: 40px;
        margin: 40px 0;
      }
    }
  }
}
.area_field {
  width: 100%;
  // height: 120px;
  margin: 35px 0 0 0;
  position: relative;
  textarea {
    position: relative;
    font-size: 13px;
    border-radius: 5px;
    padding: 10px 20px;
    background: transparent;
    color: $color-text;
    @include transition;
    @include full-width;
    @include border(1, rgba($color: $color-black, $alpha: 0.2));
    &:focus {
      box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
      border-color: $color-purple;
      & + label {
        color: $color-purple;
      }
    }
  }
  label {
    position: absolute;
    top: -22px;
    left: 0;
    display: block;
    padding-left: 10px;
    margin-bottom: 10px;
    color: rgba($color: $color-black, $alpha: 0.4);
    font-size: 0.7rem;
    @include transition;
  }
}

.input_spec_holder {
  padding-left: 15px;
  margin-left: 50%;
  margin-top: 10px;
  @include media-max(500px) {
    margin-left: 0 !important;
    padding-left: 0;
  }
  .input_label {
    font-size: 12px;
    color: #adadac;
    font-weight: lighter;
    margin-left: 1%;
  }
}
.table_inline_form {
  width: 300px;
  @include flex-align-center;
  label {
    display: none;
  }
  .input_holder {
    .form_field {
      label {
        font-size: 12px !important;
      }
      input:not([value='']) + label {
        display: none !important;
      }
      input:focus + label {
        display: none !important;
      }
    }
    .tooltip_wrap {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 4;
      width: 24px;
      height: 24px;
      svg {
        color: $color-danger;
      }
    }
  }
  button {
    margin-left: 20px;
  }
}

.inline_update_form {
  @include absolute-full;
  background: $color-white;
  z-index: 1;
  display: grid;
  grid-template-columns: 1fr 60px;
  padding: 0 10px;
  grid-gap: 0 20px;
  align-items: center;
  border-top: 1px solid #e7e3e3;
  border-bottom: 1px solid #e7e3e3;
  &.not_full {
    bottom: 0;
    height: 50px;
  }
  .input_holder {
    width: 220px !important;
    height: 35px;
    @include media-max(500px) {
      width: 170px !important;
    }
    .form_field {
      height: 35px;
      label {
        font-size: 12px !important;
      }
      input:not([value='']) + label {
        display: none !important;
      }
      input:focus + label {
        display: none !important;
      }
    }
    .tooltip_wrap {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 4;
      width: 24px;
      height: 24px;
      svg {
        color: $color-danger;
      }
    }
  }
  .search_select_wrapper {
    font-size: 13px;
    .search_select_holder {
      height: auto;
      width: 250px;
    }
    @include flex-align-center;
    .error_msg {
      margin-left: 10px;
    }
    label {
      display: none;
    }
  }
}

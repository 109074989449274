.breadcrumb_component {
  .container {
    @include media-max(567px) {
      padding: 0;
    }
    nav.breadcrumb_wrapper {
      @include flex-align-center;
      flex-wrap: wrap;
      margin: 25px 0;
      @include media-max(567px) {
        margin-top: 0;
        padding: 15px 1rem;
        background: #f8f8f8;
      }
      div {
        @include flex-align-center;
        margin: 3px;
      }
      a,
      p {
        font-size: 12px;
        color: #7367f0;
        span {
          color: black;
        }
        &.active {
          color: black;
        }
        //color: $color-text;
        //font-size: 14px;
        //font-weight: 400;
        @include media-max(567px) {
          font-size: 12px;
        }
      }
      svg {
        margin: 0 7px;
      }
    }
  }
}

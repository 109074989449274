aside.attributes_section_inner {
  margin-bottom: 20px;
  @include media-max(1200px) {
    padding: 20px;
  }
  .attributes_section_outer {
    .attributes_wrapper {
      position: relative;
      .attributes_item {
        border-bottom: 1px solid #ddd;
        padding-bottom: 20px;
        margin-bottom: 20px;
        h3 {
          color: #575560;
          margin-bottom: 1.2em;
          font-size: 12px;
          font-weight: 700;
        }
        .attribute_values {
          overflow: hidden;
          margin-top: 1.2em;
          &.active {
            max-height: 145px;
            height: auto;
          }
          .attribute_value {
            @include flex-align-center;
            cursor: pointer;
            margin-bottom: 15px;
            &:last-child {
              margin-bottom: 0;
            }
            .box {
              width: 15px;
              height: 15px;
              @include flex-center;
              border: 1px solid #dfdfdf;
              margin-right: 10px;
              border-radius: 2px;
              &.active {
                border-color: $color-primary;
                svg {
                  display: block;
                }
              }
              svg {
                width: 13px;
                height: 13px;
                color: $color-primary;
                display: none;
              }
            }
            p {
              color: #333e48;
              font-size: 0.82em;
              span {
                color: #acacac;
                font-size: 0.8em;
                margin-left: 3px;
              }
            }
          }
        }
        button {
          @include flex-align-center;
          background: transparent;
          color: #575560;
          font-size: 0.7em;
          margin-top: 20px;
          svg {
            margin-right: 3px;
            width: 16px;
            height: 16px;
          }
        }
      }
      .buttons {
        @include flex-between;
        @include media-max(768px) {
          position: fixed;
        }
        width: 95%;
        bottom: 0;
        padding: 0.5rem;
        padding-left: 0;
        background: white;
        display: flex;
        align-items: flex-start;
        button {
          padding: 5px 10px;
          background: #efecec;
          color: #333e48;
          font-size: 0.875rem;
          border-radius: 1.571em;
          @include hover {
            background: $color-black;
            color: $color-white;
          }
        }
      }
    }
  }
}

.banners img {
  cursor: pointer;
}

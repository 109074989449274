.product_not_found {
  text-align: center;
  margin: 200px 0;
  font-size: 28px;
  color: $color-black;
  font-weight: bold;
}

.single_product_page {
  color: black !important;
  .breadcrumb_item {
    cursor: pointer;
    h3 {
      font-size: 12px;
      color: #7367f0;
      font-weight: 600;
      span {
        font-weight: normal;
        color: black;
      }
    }
  }

  .product_banner {
    display: flex;
    align-items: center;
    gap: 10px;
    a {
      width: 100%;
    }
    img {
      width: 100%;
      border-radius: 5px;
      overflow: hidden;
    }
    &.promotions img {
      width: 60%;
      @include media-max(992px) {
        width: 100%;
      }
    }
  }

  .actions_banner {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: end;
    width: 100%;
    @include media-max(992px) {
      justify-content: left;
    }
    .actions_banner_item {
      z-index: 10;
      img {
        border-radius: 5px;
        height: auto;
        width: 100%;
        @include media-max(992px) {
          width: 100%;
        }
      }
    }
    &.promotions {
      justify-content: left;
      .actions_banner_item img {
        width: 300px;
        @include media-max(992px) {
          width: 100%;
        }
      }
    }
  }

  // .product_banner {
  //   width: 530px;
  //   margin: 50px auto 0 0;
  //   cursor: pointer;
  //   @include media-max(1200px) {
  //     width: 420px;
  //   }
  //   img {
  //     width: 100%;
  //     border-radius: 10px;
  //   }
  // }
  .product_out_of_stock {
    h3 {
      font-size: 18px;
      position: relative;
      margin-left: 25px;
      font-weight: 400;
      @include flex-align-center;
      &::before {
        content: '';
        position: absolute;
        left: -25px;
        width: 15px;
        height: 15px;
        background: rgb(221, 28, 28);
        border-radius: 50%;
      }
    }
    p {
      margin: 20px 0;
      color: #949494;
      font-size: 15px;
    }
    input {
      width: 250px;
      border: 1px solid #ebebeb;
      background: $color-white;
      padding: 10px;
      border-radius: 6px;
      margin-right: 20px;
    }
    button {
      margin: 10px 0;
      padding: 10px;
      background: $color-primary;
      border-radius: 6px;
      color: $color-white;
      font-size: 14px;
      font-weight: 500;
    }
  }
  .product_content {
    .mobile_track {
      display: none;
      @include media-max(992px) {
        @include flex-between;
        margin-bottom: 20px;
      }
      span {
        background: #ea5455;
        color: $color-white;
        font-weight: bold;
        font-size: 14px;
        padding: 6px;
      }
      .favorite_icon {
        @include media-max(992px) {
          font-size: 20px;
          cursor: pointer;
          color: $color-black;
        }
      }
    }
    .sticky-mobile {
      transition: transform 0.3s ease-in-out;
      transform: translateX(100%); /* Initially hide the element */
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 1000;
      &.visible {
        transform: translateX(0); /* Show the element */
      }

      // .gradient {
      //   height: 60px;
      //   background: linear-gradient(rgba(255, 255, 255, 0), white);
      // }
      .sticky-mobile_content {
        background: white;
        z-index: 10;
        padding: 10px;
      }
      .product_name {
        margin-bottom: 7px;
        font-weight: bold;
        position: relative;
        font-size: 12px;
      }

      .bottom {
        display: flex;
        align-items: center;

        gap: 1rem;
        margin-bottom: 0.5rem;
        .price {
          font-size: 32px;
          font-weight: 600;

          text-align: center;
          sup {
            font-size: 16px;
          }
        }
      }
      .actions {
        display: flex;
        align-items: center;

        width: 100%;
        button {
          text-transform: none !important;
        }
      }
    }
    .product_data {
      // display: grid;
      // grid-template-columns: 600px 1fr;
      // grid-gap: 50px;

      position: relative;
      @include media-max(1480px) {
        // grid-template-columns: 50% 1fr;
        grid-gap: 20px;
      }
      @include media-max(1200px) {
        // grid-template-columns: 50% 1fr;
      }
      @include media-max(992px) {
        width: 100%;
        // grid-template-columns: 100%;
        position: relative;
        margin: auto;
      }
      .product_photos {
        display: grid;
        grid-template-columns: 2fr 5fr;
        gap: 0.5rem;
        @include media-max(992px) {
          display: flex;
          flex-direction: column;
        }
        .active_photo {
          width: 100%;
          height: 500px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          border: 1px solid $color-grey-border;
          border-radius: 15px;
          padding: 0.5rem;
          @include media-max(1480px) {
            height: 450px;
          }
          @include media-max(1200px) {
            height: 300px;
          }
          @include media-max(992px) {
            height: 250px;
            position: relative;
            order: -1;
          }
          img {
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
            margin: auto 0;
          }
          .arrow {
            display: none;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
            font-size: 24px;
            @include media-max(992px) {
              display: block;
            }
            &.left {
              left: 0;
            }
            &.right {
              right: 0;
            }
          }
        }
        .call_center {
          display: flex;
          align-items: center;
          background-color: #f8f9fa;
          padding: 5px 10px;
          border-radius: 5px;
          width: 100%;
          grid-column: 1 / -1;
          .call_center_right {
            .heading {
              font-size: 15px;
              font-weight: 700;
              text-transform: uppercase;
            }
            p,
            a,
            span {
              font-size: 12px;
              font-weight: 500;
            }
            span a {
              color: black;
              font-weight: 700;
            }
            .bottom {
              margin: 10px 0;
            }
          }
        }
        .other_photos {
          max-height: 500px;
          .swiper-container {
            width: 100%;
          }
          .swiper-slide {
            display: flex;

            .image-holder {
              border: 1px solid $color-grey-border;
              border-radius: 15px;
            }
            img {
              padding: 1rem;

              margin: auto 0;
              @include media-max(992px) {
                padding: 5px;
              }
            }
          }
          .swiper-button-prev {
            @include media-min(1300px) {
              display: none;
            }

            // @include media-max(992px) {
            //   display: flex;
            // }
          }
          .swiper-button-next {
            @include media-min(1300px) {
              transform: rotate(90deg);
              left: 5px;
            }
          }
          .swiper-button-next,
          .swiper-button-prev,
          .swiper-button-next::after,
          .swiper-button-prev::after {
            font-size: 20px !important;
            @include media-max(992px) {
              display: none;
            }
          }
        }
        .other_photos,
        .other_photos_modal {
          //display: flex;
          // grid-template-columns: repeat(4, 1fr);
          // grid-gap: 10px;

          @include media-max(992px) {
            display: grid;
            grid-gap: 0px;
          }
          div {
            @include media-min(1300px) {
              @include full-size;
              border: 1px solid transparent;
            }

            //padding: 5%;
            cursor: pointer;

            &.active {
              border-color: #dedede;
            }
            img {
              @include full-size;
              margin: auto 0;
              height: auto !important;
            }
            @include media-max(992px) {
            }
          }
        }
        .other_photos_modal {
          display: grid;
          grid-template-columns: repeat(4, 100px);
          justify-content: center;
          margin-top: 20px;
        }
      }
    }
    .product_details {
      height: 100%;
      .product_name {
        font-size: 18px;
        font-weight: 600;
        font-family: 'Segoe UI';
        overflow: hidden;
        @include media-max(1200px) {
          font-size: 24px;
        }
        @include media-max(992px) {
          text-align: left;
          font-size: 20px;
        }
      }
      .product_info_bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include media-max(992px) {
          margin-top: 10px;
          flex-wrap: wrap;
          gap: 10px;
        }
        .product_info {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          margin-top: 0.5rem;
          @include media-max(992px) {
            width: 100%;
            gap: 2rem;
            span {
              display: none;
            }
          }
        }
        .product_info_name {
          font-size: 12px;
          font-weight: 600;
          @include media-max(992px) {
            display: flex;
            flex-direction: column;
          }
          .product_sku_value {
            display: inline;
            font-size: 12px;
            color: #afafaf;
            font-weight: 400;
          }
        }
        .product_info_links {
          display: flex;
          gap: 0.5rem;
          align-items: center;
          a {
            display: flex;
            align-items: center;
            gap: 0.2rem;
            font-size: 12px;
            text-decoration: none;
            cursor: pointer;
          }
        }
      }

      .product_details_inner {
        display: flex;
        gap: 10px;
        height: 100%;
        @include media-max(992px) {
          flex-wrap: wrap;
        }
        //align-items: center;
        .product_details_inner_left {
          height: 100%;
          width: 50%;
          display: flex;
          flex-direction: column;
          //gap: 10px;
          @include media-max(1481px) {
            //gap: 0;
          }
          @include media-max(992px) {
            width: 100%;
            gap: 0;
          }
          .specs_link {
            font-size: 13px;
            margin: 15px 0;
          }
          .prices_row {
            display: flex;
            align-items: center;
            gap: 10px;
            margin: 10px 0;
            @include media-max(992px) {
              margin-bottom: 0;
            }
            .web_price {
              border-right: 1px solid $color-grey-border;
              padding-right: 1rem;
              h2 {
                font-size: 13px;
                font-weight: 500;
              }
              p {
                font-size: 36px;
                font-weight: 600;
              }
            }
            .credit {
              p {
                font-size: 13px;
              }
              .credit_rate {
                font-size: 20px;
                font-weight: 600;
              }
              a {
                font-size: 13px;
              }
            }
          }
          h2.retail_price {
            font-size: 13px;
            font-weight: 500;
          }
          p.retail_price {
            font-size: 20px;
            font-weight: 600;
            text-decoration: line-through;
          }
          .saving {
            color: #8bc34a;
            font-size: 15px;
            margin: 0.5rem 0;
            span {
              font-weight: 600;
            }
          }
          p.note {
            font-size: 10px;
            color: #111111;
          }
          p.delivery {
            display: flex;
            align-items: center;
            gap: 5px;
            font-size: 14px;
            margin: 15px 0;
            svg {
              height: 20px;
              width: 20px;
            }
          }
          .squares {
            position: relative;
            display: flex;
            align-items: center;
            gap: 1rem;
            width: 100%;
            margin-top: 15px;
            .squares_item {
              display: flex;
              flex-direction: column;
              gap: 5px;
              width: 50%;
              padding: 15px 10px;
              background-color: #f8f9fa;
              border-radius: 5px;
              .big {
                font-size: 14px;
                font-weight: 700;
              }
              .smaller,
              a {
                font-size: 12px;
                font-weight: 500;
              }
            }
            .shipping_modal {
              position: absolute;
              top: -50%;
              left: 20%;
              width: 100%;
              height: fit-content;
              z-index: 100;
              background-color: #f8f9fa;
              border-radius: 5px;
              padding: 1rem;
              border: 1px solid $color-grey-border;
              font-size: 14px;
            }
          }
          .bottom_part_wrapper {
            position: absolute;
            bottom: 0;
            //margin-top: 70px;
            @include media-max(992px) {
              position: relative;
            }
          }
          .quantity_row {
            display: flex;
            align-items: center;
            border-radius: 25px;
            @include media-max(992px) {
              //display: none;
            }
            .text {
              font-size: 12px;
              width: 100%;
            }
            p {
              font-size: 20px;
              @include media-max(1200px) {
                font-size: 14px;
              }
            }
            .quantity {
              display: grid;
              grid-template-columns: 35px 45px 35px;
              grid-template-rows: 40px;
              grid-gap: 6px;
              margin-left: 20px;
              @include media-max(1200px) {
                grid-template-columns: 30px 40px 30px;
              }
              & > div {
                border-radius: 9px;
                // border: 1px solid #ebebeb;
                // color: #757575;
                //font-weight: bold;
                font-size: 22px;

                @include flex-center;
                @include full-size;
                cursor: pointer;
                @include media-max(1200px) {
                  font-size: 18px;
                }
              }
            }
          }
          .heart_container {
            display: flex;
            border-radius: 25px;
            background-color: #f8f9fa;
            justify-content: center;
            padding: 0.5rem 1rem;
            width: 100%;
            svg {
              margin: 0 auto;
            }
          }
          .actions {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            .addToCart {
              svg {
                color: white;
              }
            }
          }
          .sales_centers {
            display: flex;

            justify-content: center;
            font-size: 13px;
            margin: 0.5rem auto;
            justify-self: center;

            p {
              display: flex;
              align-items: center;
              gap: 5px;
            }
            cursor: pointer;
            svg {
              height: 20px;
              width: 20px;
            }
          }
        }
        .product_details_inner_right {
          width: 50%;
          @include media-max(992px) {
            width: 100%;
          }
          .badges {
            display: flex;
            flex-direction: column;
            gap: 10px;
            .badge {
              display: flex;
              align-items: center;
              gap: 10px;
              background-color: #f8f9fa;
              border-radius: 5px;
              padding: 15px 30px;
              .badge_left {
                min-width: 30%;
                display: flex;
                img {
                  //width: 50%;
                  height: auto;
                  margin: 0 auto;
                  @include media-max(1480px) {
                    width: 70%;
                  }
                }
              }
              .badge_right {
                :nth-child(1) {
                  font-size: 15px;
                  font-weight: bold;
                }
                :nth-child(2),
                a {
                  font-size: 12px;
                  font-weight: 500;
                }
              }
            }
          }
        }
      }

      .details_inner {
        display: grid;
        // grid-template-columns: repeat(2, 1fr);
        // grid-gap: 50px;
        margin-bottom: 50px;
        @include media-max(1200px) {
          // grid-gap: 20px;
        }
        @include media-max(992px) {
          // display: block;
        }
        .left {
          .registered_info {
            color: #8bc34a;
            font-weight: bold;
            font-size: 28px;
            margin-bottom: 70px;
            @include media-max(1200px) {
              font-size: 24px;
            }
            @include media-max(992px) {
              display: none;
            }
          }
          .product_prices {
            @include media-max(992px) {
              @include flex-column-center;
            }
            .price {
              display: flex;
              align-items: flex-end;
              width: fit-content;
              color: $color-black;
              &.retail_price {
                font-size: 15px;
                position: relative;
                margin-bottom: 30px;
                @include media-max(1200px) {
                  font-size: 16px;
                  line-height: 24px;
                }
                @include media-max(992px) {
                  color: #707070;
                  margin: 0;
                }
                &::after {
                  content: '';
                  position: absolute;
                  top: 50%;
                  left: -5px;
                  right: -5px;
                  transform: translateY(-50%);
                  height: 2px;
                  background: $color-black;
                  @include media-max(992px) {
                    background: #707070;
                  }
                }
                h3 {
                  font-size: 20px;
                  font-weight: 700;
                  padding: 0 5px;
                  @include media-max(1200px) {
                    font-size: 18px;
                  }
                }
              }
              &.web_price {
                @include media-max(992px) {
                  margin: 10px 0;
                }
                p {
                  font-size: 23px;
                  line-height: 22px;
                  @include media-max(1200px) {
                    font-size: 16px;
                  }
                  @include media-max(992px) {
                    display: none;
                  }
                }
                h3 {
                  color: #4990e2;
                  font-weight: bold;
                  font-size: 40px;
                  margin: 0 5px;
                  line-height: 32px;
                  @include media-max(1200px) {
                    font-size: 24px;
                    line-height: 26px;
                  }
                  @include media-max(992px) {
                    color: $color-black;
                    font-weight: bold;
                    font-size: 33px;
                  }
                }
                span {
                  color: #4990e2;
                  font-size: 25px;
                  font-weight: 400;
                  line-height: 24px;
                  @include media-max(1200px) {
                    font-size: 18px;
                  }
                  @include media-max(992px) {
                    color: $color-black;
                    font-weight: bold;
                    font-size: 33px;
                  }
                }
              }
              &.saving_price {
                margin: 25px 0 30px 0;
                @include media-max(992px) {
                  background: #ea5455;
                  border-radius: 30px;
                  align-items: center;
                  padding: 7px 15px;
                  color: $color-white;
                  font-weight: bold;
                  margin: 7px 0 0 0;
                }
                p {
                  font-size: 20px;
                  @include media-max(1200px) {
                    font-size: 16px;
                  }
                  @include media-max(992px) {
                    font-size: 14px;
                  }
                }
                h3 {
                  margin: 0 5px;
                  font-weight: 900 !important;
                  @include media-max(1200px) {
                    font-size: 18px;
                  }
                  @include media-max(992px) {
                    font-size: 14px;
                  }
                }
                h3,
                span {
                  font-size: 25px;
                  font-weight: bold;
                  @include media-max(1200px) {
                    font-size: 18px;
                  }
                  @include media-max(992px) {
                    font-size: 14px;
                  }
                }
              }
            }
            .align {
              width: fit-content;
              .pdv {
                text-align: right;
                color: #b5b5b5;
                font-size: 17px;
                margin-top: 5px;
                @include media-max(1200px) {
                  font-size: 14px;
                }
                @include media-max(992px) {
                  text-align: center;
                  font-size: 12px;
                }
              }
            }
          }
          // .quantity_row {
          //   display: flex;
          //   align-items: flex-end;
          //   @include media-max(992px) {
          //     display: none;
          //   }
          //   p {
          //     font-size: 20px;
          //     @include media-max(1200px) {
          //       font-size: 14px;
          //     }
          //   }
          //   .quantity {
          //     display: grid;
          //     grid-template-columns: 35px 45px 35px;
          //     grid-template-rows: 40px;
          //     grid-gap: 6px;
          //     margin-left: 20px;
          //     @include media-max(1200px) {
          //       grid-template-columns: 30px 40px 30px;
          //       grid-template-rows: 33px;
          //     }
          //     & > div {
          //       border-radius: 9px;
          //       border: 1px solid #ebebeb;
          //       color: #757575;
          //       font-weight: bold;
          //       font-size: 25px;
          //       @include flex-center;
          //       @include full-size;
          //       cursor: pointer;
          //       @include media-max(1200px) {
          //         font-size: 18px;
          //       }
          //     }
          //   }
          // }
          .ticket_card {
            width: 330px;
            background: #8bc34a;
            padding: 15px;
            border-radius: 17px;
            text-align: center;
            text-transform: uppercase;
            margin-top: 30px;
            @include media-max(1200px) {
              width: 280px;
            }
            @include media-max(992px) {
              width: 100%;
              background: $color-black;
              border-radius: 0;
              padding: 20px;
            }
            @include media-max(554px) {
              margin-left: -0.9375rem;
              width: calc(100% + 1.875rem);
            }
            h2 {
              color: $color-white;
              font-size: 28px;
              @include media-max(1200px) {
                font-size: 22px;
              }
              @include media-max(992px) {
                font-size: 28px;
              }
            }
            p {
              font-size: 20px;
              margin: 10px 0 15px 0;
              @include media-max(1200px) {
                font-size: 16px;
              }
              @include media-max(992px) {
                font-size: 17px;
                color: #8bc34a;
                font-weight: bold;
                margin: 10px 0 20px 0;
              }
            }
            a {
              padding: 7px 20px;
              background: $color-white;
              color: $color-black;
              font-size: 13px;
              font-weight: bold;
              border-radius: 30px;
              @include media-max(992px) {
                font-size: 15px;
                background: #8bc34a;
                color: $color-black;
              }
            }
          }
        }
        .mobile_registered_info {
          display: none;
          @include media-max(992px) {
            color: $color-primary;
            display: block;
            text-align: center;
            margin-top: 20px;
            font-size: 18px;
          }
        }
        .buttons {
          display: none;
          @include media-max(992px) {
            display: grid;
            margin: 30px 0;
          }
        }
        .right {
          @include media-max(992px) {
            display: flex;
            flex-direction: column;
          }
          .row {
            @include flex-align-center;
            margin-bottom: 60px;
            @include media-max(1200px) {
              margin-bottom: 30px;
            }
            @include media-max(992px) {
              order: 2;
              margin: 20px 0 0 0;
              display: grid;
              grid-template-columns: 44px 1fr;
              grid-gap: 12px;
            }
            svg {
              color: $color-primary;
              font-size: 24px;
              margin-right: 20px;
              @include media-max(1200px) {
                font-size: 20px;
                margin-right: 10px;
              }
              @include media-max(992px) {
                font-size: 27px;
                margin-right: 0;
                justify-self: center;
              }
            }
            a,
            p {
              font-size: 17px;
              color: $color-black;
              @include media-max(1200px) {
                font-size: 14px;
              }
              @include media-max(992px) {
                font-size: 15px;
              }
            }
            &.phones {
              @include media-max(992px) {
                order: 3;
              }
              a {
                @include media-max(992px) {
                  font-weight: normal;
                  font-size: 13px;
                }
              }
              & > div {
                @include flex-align-center;
                flex-wrap: wrap;
              }
              span {
                margin: 0 5px;
              }
            }
          }
          .installments_without_interest {
            @include flex-column-center;
            @include media-max(992px) {
              order: 1;
              flex-direction: row;
              justify-content: flex-start;
            }
            img {
              @include media-max(1200px) {
                width: 100px;
                height: 100px;
              }
              @include media-max(992px) {
                width: 44px;
                height: 44px;
              }
            }
            p {
              text-align: center;
              font-size: 17px;
              color: $color-black;
              margin-top: 5px;
              @include media-max(1200px) {
                font-size: 12px;
              }
              @include media-max(992px) {
                margin: 0 0 0 12px;
                font-size: 15px;
              }
            }
          }
        }
      }
      .buttons {
        display: grid;
        align-items: center;
        grid-gap: 60px;
        grid-template-columns: repeat(2, 235px);
        grid-template-rows: 40px;
        @include media-max(1200px) {
          grid-template-columns: repeat(2, 200px);
          grid-gap: 20px;
        }
        @include media-max(992px) {
          grid-template-columns: 80px 1fr;
          grid-gap: 20px;
          display: none;
        }
        .quantity_row {
          display: none;
          @include media-max(992px) {
            display: block;
            p {
              display: none;
            }
            .quantity {
              @include flex-between;
              font-size: 30px;
              .selected {
                margin: 0 5px;
              }
            }
          }
        }
        button {
          border-radius: 11px;
          @include full-size;
          display: grid;
          grid-template-columns: 40px 1fr;
          border: 1px solid #4990e2;
          background: $color-white;
          align-items: center;
          justify-items: center;
          text-align: center;
          color: #949494;
          font-size: 17px;
          @include media-max(1200px) {
            font-size: 14px;
          }
          @include media-max(992px) {
            text-transform: uppercase;
            font-weight: 500;
            font-size: 15px;
            width: 100%;
            height: 40px;
            display: block;
          }
          span {
            background: #4990e2;
            @include full-size;
            @include flex-center;
            border-radius: 11px;
            font-size: 20px;
            color: $color-white;
            @include media-max(992px) {
              display: none;
            }
          }
          &.cart_button {
            background: #4990e2;
            color: $color-white;
            span {
              background: $color-white;
              color: #4990e2;
            }
            @include media-max(992px) {
              background: #0275d8;
            }
          }
          &.wish_list_button {
            @include media-max(992px) {
              display: none;
            }
          }
        }
      }
    }
  }
  .product_tabs_section {
    margin-top: 70px;
    // border: 1px solid #e9e9ea;
    padding-bottom: 50px;
    @include media-max(992px) {
      margin: 0;
      padding: 0;
      border: 0;
    }
    .grid-right {
      display: none;
    }
    .nav {
      // background: #f8fafe;
      // display: grid;
      // grid-template-columns: repeat(4, 1fr);
      display: flex;
      align-items: center;
      justify-items: center;
      gap: 10px;
      padding: 20px 0 30px 0;
      @include media-max(992px) {
        display: block;
        padding: 0;
        background: transparent;
        display: 'flex';
        flex-direction: column;
        justify-content: center;
        align-items: start;
      }
      p {
        margin-right: 15px;
        text-transform: uppercase;
        color: #707070;
        font-size: 20px;
        font-weight: bold;
        font-size: 20px;
        font-weight: 600;
        border-bottom: 1px solid transparent;
        position: relative;
        cursor: pointer;

        @include media-max(1200px) {
          font-size: 14px;
        }
        @include media-max(992px) {
          display: grid;
          grid-template-columns: 1fr 20px;
          grid-gap: 10px;
          padding: 10px;
          border-bottom: 1px solid #d5d5d5;
        }
        &.active {
          border-color: transparent;
          &::after {
            content: '';
            position: absolute;
            left: -1px;
            right: -1px;
            bottom: -14px;
            height: 4px;
            background: $color-primary;
            border-radius: 30px;
            @include media-max(992px) {
              display: none;
            }
          }
        }
        @include media-max(992px) {
          &.active + .mobile {
            display: block;
          }
        }
        svg {
          display: none;
          @include media-max(992px) {
            display: block;
            font-size: 20px;
          }
        }
      }
    }
    .box {
      @include media-max(992px) {
        display: none;
      }
    }
    .mobile {
      display: none;
    }
    .specifications {
      @include media-max(992px) {
        background: #f8fafe;
      }
      .list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px 50px;
        @include media-max(992px) {
          grid-template-columns: 100%;
          grid-gap: 0;
          border-bottom: 1px solid #dfdfdf;
        }
        .item {
          border-bottom: 1px solid #dfdfdf;
          display: grid;
          grid-template-columns: 200px 1fr;
          padding: 7px;
          grid-gap: 10px;
          align-items: center;

          @include media-max(1480px) {
            grid-template-columns: 170px 1fr;
            padding: 10px;
          }
          @include media-max(992px) {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: flex-start;
            border-bottom: 0;
          }
          .label {
            font-size: 17px;
            font-weight: 500;
            @include media-max(1480px) {
              font-size: 15px;
            }
            @include media-max(992px) {
              color: #707070;
              font-size: 14px;
              font-weight: 400;
              line-height: 16px;
            }
          }
          .value {
            font-size: 14px;
            font-weight: 300;
            @include media-max(1480px) {
              font-size: 17px;
            }
            @include media-max(992px) {
              color: #111;
              font-size: 14px;
              font-weight: bold;
              line-height: 16px;
            }
          }
        }
      }
      .list_is_empty {
        text-align: center;
        margin: 50px 0;
        font-size: 18px;
      }
    }
    .description_tab {
      @include media-max(992px) {
        background: #f8fafe;
      }
      p {
        font-size: 18px;
        line-height: 32px;
        @include media-max(992px) {
          font-size: 15px;
          line-height: normal;
        }
      }
    }
    .declaration_tab {
      @include media-max(992px) {
        background: #f8fafe;
        border-bottom: 1px solid #dfdfdf;
      }
      .declaration_table {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px 50px;
        @include media-max(992px) {
          grid-template-columns: 100%;
          grid-gap: 0;
        }
        .row {
          border-bottom: 1px solid #dfdfdf;
          display: grid;
          grid-template-columns: 150px 1fr;
          padding: 7px;
          grid-gap: 10px;
          align-items: center;
          @include media-max(1480px) {
            grid-template-columns: 130px 1fr;
          }
          @include media-max(992px) {
            grid-template-columns: 100%;
            padding: 10px;
            grid-gap: 0;
            border-bottom: 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          }
          .cell {
            &:first-child {
              font-weight: 500;
              @include media-max(992px) {
                color: #707070;
                font-size: 14px;
                font-weight: 400;
              }
            }
            font-size: 17px;
            @include media-max(1480px) {
              font-size: 14px;
            }
            @include media-max(992px) {
              color: #111;
              font-size: 14px;
              font-weight: bold;
              max-width: 50%;
            }
          }
        }
      }
    }
    .calculator_tab {
      @include media-max(992px) {
        background: #f8fafe;
      }
      .subtitle {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 20px;
        @include media-max(992px) {
          font-size: 13px;
          border-bottom: 0;
          margin-bottom: 10px;
        }
      }
      .row {
        border-bottom: 1px solid #dfdfdf;
        display: grid;
        grid-template-columns: 200px 1fr;
        grid-gap: 10px;
        align-items: center;
        padding: 20px 10px;
        font-size: 17px;
        @include media-max(1480px) {
          grid-template-columns: 160px 1fr;
          font-size: 14px;
        }
        @include media-max(992px) {
          grid-gap: 0;
          padding: 10px;
          grid-template-columns: 100%;
        }
        .label {
          font-weight: 600;
          @include media-max(992px) {
            color: #707070;
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 5px;
          }
        }
        input {
          width: 200px;
          height: 40px;
          border-radius: 19px;
          background: #eff4ff;
          padding: 0 20px;
          font-size: 14px;
          @include media-max(1200px) {
            height: 36px;
            padding: 0 15px;
          }
          @include media-max(992px) {
            width: 100%;
            height: 35px;
            background: #eee;
          }
        }
        .right {
          position: relative;
          .dropdown {
            position: absolute;
            top: calc(100% + 10px);
            left: 0;
            background: $color-white;
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            border-radius: 10px;
            overflow: hidden;
            li {
              padding: 7px 20px;
              text-align: center;
              font-size: 16px;
              color: $color-black;
              cursor: pointer;
              @include hover {
                background: $color-grey-10;
              }
            }
          }
        }
      }
    }
  }
  .questions_and_answers {
    display: flex;
    flex-direction: column;
    margin: 100px 0;
    margin-top: 0px;
    @include media-max(425px) {
      margin-top: 20px;
    }
    @include media-max(1200px) {
      grid-template-columns: 100%;
    }
    @include media-max(992px) {
      margin: 50px 0;
    }
    .ask_form {
      @include media-max(992px) {
        padding: 20px;
      }
      @include media-max(425px) {
        padding-top: 0;
      }
      .subtitle {
        @include flex-align-center;
        margin-bottom: 70px;
        @include media-max(992px) {
          margin-bottom: 30px;
        }
        img {
          @include media-max(992px) {
            width: 40px;
          }
        }
        p {
          margin-left: 20px;
          width: 105px;
          font-size: 16px;
          font-weight: 700;
          @include media-max(992px) {
            font-size: 14px;
            width: auto;
          }
        }
      }
      form {
        @include flex-column;
        .field {
          display: flex;
          flex-direction: column;
          margin-bottom: 20px;
          @include media-max(992px) {
            grid-template-columns: 100%;
            grid-gap: 10px;
          }
          label {
            font-size: 14px;
            font-weight: 700;
            color: #707070;
            margin-bottom: 10px;
            @include media-max(992px) {
              font-size: 14px;
            }
          }
          input {
            width: 100%;
            height: 35px;
            // border-radius: 6px;
            background: $color-white;
            border: 1px solid #d5d5d5;
            border-radius: 5px;
            padding: 0 10px;
            font-size: 14px;
            &::placeholder {
              /* Chrome, Firefox, Opera, Safari 10.1+ */
              color: #e3e3e3;
            }
          }
          textarea {
            width: 100%;
            height: 120px;
            // border-radius: 6px;
            background: $color-white;
            border: 1px solid #d5d5d5;
            border-radius: 5px;
            padding: 10px;
            font-size: 14px;
            &::placeholder {
              /* Chrome, Firefox, Opera, Safari 10.1+ */
              color: #e3e3e3;
            }
          }
        }
        .error_msg {
          margin: -10px 0 20px 150px;
          @include media-max(992px) {
            margin: -15px 0 10px 0;
          }
        }
        button {
          width: max-content;
          @include media-max(992px) {
            font-size: 14px;
          }
        }
      }
    }
    .comments_inner {
      margin-bottom: 20px;
      .answers_inner {
        overflow-y: scroll;
        height: 400px;
        &::-webkit-scrollbar {
          width: 14px;
          @include media-max(992px) {
            width: 0;
          }
        }
        &::-webkit-scrollbar-track {
          background: $color-white;
        }
        &::-webkit-scrollbar-thumb {
          background: #dfdfdf;
          border-radius: 30px;
        }
        &::-webkit-scrollbar-thumb:hover {
          background: #c3c3c3;
        }
        .answer_group {
          @include media-max(992px) {
            margin-bottom: 20px;
            border: 1px solid #d5d5d5;
            padding: 12px;
            border-radius: 6px;
          }
          .answer_item {
            display: grid;
            grid-template-columns: 150px 1fr;
            grid-gap: 30px;
            margin-bottom: 20px;
            align-items: center;
            @include media-max(992px) {
              grid-template-columns: 100%;
              grid-gap: 10px;
            }
            .row {
              text-align: center;
              @include media-max(992px) {
                text-align: left;
                @include flex-between;
              }
              .name {
                font-size: 20px;
                font-weight: 500;
                @include media-max(1480px) {
                  font-size: 18px;
                }
                @include media-max(992px) {
                  font-size: 13px;
                  color: $color-primary;
                }
              }
              .date {
                color: #747373;
                font-size: 12px;
              }
            }
            .answer {
              font-weight: 500;
              font-size: 17px;
              @include media-max(1480px) {
                font-size: 14px;
              }
            }
          }
        }
        .sub_answer {
          border-radius: 6px;
          background: #f5f5f5;
          padding: 15px 15px 10px 15px;
          margin-left: 180px;
          position: relative;
          width: 65%;
          @include media-max(992px) {
            margin: 0;
            width: 100%;
          }
          &::before {
            content: '';
            position: absolute;
            top: -6px;
            left: 20px;
            width: 12px;
            height: 12px;
            transform: rotate(45deg);
            background: #f5f5f5;
          }
          p {
            color: #1d2b2a;
            font-size: 16px;
            font-weight: 500;
            @include media-max(1480px) {
              font-size: 14px;
            }
          }
          .row {
            margin-top: 20px;
            @include flex-align-center;
            justify-content: flex-end;
            .name {
              margin-right: 5px;
              font-weight: 300;
              color: #707070;
            }
            .date {
              font-weight: 300;
              font-size: 14px;
              color: #707070;
            }
          }
        }
      }
    }
  }
}
.eRHPMY {
  h3 {
    font-weight: 700 !important;
    font-size: 23px !important;
  }
}
.MuiButton-outlinedPrimary {
  color: #0878ea !important;
}
.sc-crrszt {
  border-radius: 4px !important;
}
.sc-clsFYl {
  color: #0878ea !important;
}
.fkTDqK {
  .value {
    text-align: end;
  }
}

.product_row_images {
  display: grid;
  grid-template-columns: 2fr 5fr;
  //flex-direction: row;
  width: 100%;
  //height: 100vh; /* Adjust this as needed */
  gap: 10px;
  margin-top: 2rem;
  @include media-max(992px) {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0.5rem;
  }
  h3,
  p {
    font-size: 12px;
  }
  .images_holder {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
    gap: 10px;
    @include media-max(992px) {
      display: flex;
      flex-wrap: wrap;
    }
    .image_holder {
      height: 100%;
    }
    img {
      //object-fit: cover;
      width: 100%;
      height: 100%; /* To maintain the same height as the div */
    }
  }
}

.landing_page_first {
  width: 100%;
  .header {
    width: 100%;
    padding-left: 10%;
    padding-right: 10%;
    display: block;
    @include media-max(750px) {
      display: flex;
      flex-direction: column;
    }
    .banner {
      width: 35%;
      aspect-ratio: 1;
      display: inline-block;
      border-radius: 0.5vw;
      @include media-max(750px) {
        width: 100%;
      }
    }
    .header_text {
      width: 65%;
      top: 0;
      display: inline-block;
      vertical-align: top;
      padding: 2%;
      @include media-max(750px) {
        width: 100%;
      }
      .top_description {
        width: 100%;
      }

      .tab {
        width: 100%;
        margin-top: 5%;
        box-shadow: none;
        border: 1px solid lightgray;
        padding: 2%;
        border-radius: 1em;
        display: flex;
        justify-content: space-around;
        @include media-max(750px) {
          display: flex;
          flex-direction: column;
        }
        img {
          width: 15%;
          display: inline-block;
          vertical-align: top;
          margin: auto;
          aspect-ratio: 1;
          object-fit: cover;
          @include media-max(750px) {
            width: 33%;
          }
        }
        .card1 {
          width: 80%;
          display: inline-block;
          vertical-align: top;
          padding-left: 2%;
          @include media-max(750px) {
            width: 100%;
          }
        }
      }
    }
    .bottom_description {
      width: 100%;
    }
    .preview {
      width: 100%;
      padding-top: 5%;
      padding-bottom: 5%;
      .products_grid_wrapper {
        .container {
          .swiper-slide {
            @include media-max(750px) {
              padding-bottom: 15%;
            }
          }
        }
      }
    }
  }
}

.landing_page_second {
  width: 100%;
  .header {
    width: 100%;
    padding-left: 10%;
    padding-right: 10%;
    @include media-max(750px) {
      display: flex;
      flex-direction: column;
    }
    .banner {
      width: 100%;
      border-radius: 0.5vw;
      @include media-max(750px) {
        width: 100%;
      }
    }
    .header_text {
      width: 100%;
      top: 0;
      vertical-align: top;
      padding: 2%;
      @include media-max(750px) {
        width: 100%;
      }
      .top_description {
        width: 100%;
      }
      .cards::-webkit-scrollbar {
        display: none;
      }
      .cards {
        display: flex;
        justify-content: space-between;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        @include media-max(750px) {
          flex-direction: column;
        }
        .card {
          width: 23%;
          margin-top: 5%;
          box-shadow: none;
          border: 1px solid lightgray;
          padding: 1%;
          border-radius: 1em;
          display: flex;
          justify-content: space-around;
          @include media-max(750px) {
            width: 100%;
          }
          img {
            width: 15%;
            display: inline-block;
            vertical-align: top;
            margin: auto;
            aspect-ratio: 1;
            object-fit: cover;
            @include media-max(750px) {
              width: 33%;
            }
          }
          .card1 {
            width: 80%;
            overflow: hidden;
            display: inline-block;
            vertical-align: top;
            padding-left: 2%;
            @include media-max(750px) {
              width: 100%;
            }
          }
        }
      }
    }
    .bottom_description {
      width: 100%;
    }
    .preview {
      width: 100%;
      padding-top: 5%;
      padding-bottom: 5%;
    }
  }
}

.landing_page_third {
  width: 100%;
  .header {
    width: 100%;
    padding-left: 10%;
    padding-right: 10%;
    display: block;
    @include media-max(750px) {
      display: flex;
      flex-direction: column;
    }
    .banner {
      width: 30%;
      aspect-ratio: 1;
      display: inline-block;
      object-fit: contain;
      border-radius: 0.5vw;
      @include media-max(750px) {
        width: 100%;
      }
    }
    .header_text {
      width: 65%;
      top: 0;
      display: inline-block;
      vertical-align: top;
      padding: 2%;
      @include media-max(750px) {
        width: 100%;
      }
      .top_description {
        width: 100%;
      }
    }
    .bottom_description {
      width: 100%;
    }
    .preview {
      width: 100%;
      padding-top: 5%;
      padding-bottom: 5%;
      display: flex;
      justify-content: center;
      @include media-max(750px) {
        display: block;
      }
      .category {
        width: 12.5%;
        display: inline-block;
        overflow: hidden;
        aspect-ratio: 1;
        @include media-max(750px) {
          width: 50%;
          aspect-ratio: 1;
          padding: 1%;
        }
        img {
          object-fit: contain;
          width: 100%;
        }
      }
    }
  }
}

.products_grid_wrapper {
  overflow: visible;
  margin-top: 76px;
  .container {
    position: relative;
  }
  .swiper-container {
    position: unset;
  }
  .swiper-pagination-bullets {
    bottom: -30px !important;
    @include media-min(1000px) {
      right: 20px !important;
      top: 5px !important;
      left: unset !important;
      bottom: unset !important;
      width: fit-content !important;
    }

    span {
      height: 12px;
      width: 12px;
      @include media-max(768px) {
      }
    }
  }
  @include media-max(768px) {
    margin-top: 40px;
  }
  sup {
    font-size: 20px;
  }
  h1.title {
    font-weight: 600;
    font-size: 20px;
    padding-bottom: 10px;
    margin-bottom: 40px;

    position: relative;
    @include media-max(768px) {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 20px;
    }
    // &::after {
    //   content: '';
    //   position: absolute;
    //   bottom: -1px;
    //   height: 2px;
    //   left: 0;
    //   width: 270px;
    //   background: $color-primary;
    //   @include media-max(768px) {
    //     width: 200px;
    //   }
    // }
  }
  .swiper-wrapper {
    padding-bottom: 2px;
  }
  .products_grid_holder {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 20px;
    @include media-max(1400px) {
      grid-template-columns: repeat(4, 1fr);
    }
    @include media-max(1200px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @include media-max(768px) {
      grid-template-columns: repeat(2, 49%);
      grid-gap: 15px;
    }
  }
  .horizontal_banner_inner {
    margin-top: 1%;
    margin-bottom: 1%;
  }
}

.products_navigation_paginaton_inner {
  @include flex-between;
  @include media-max(768px) {
    flex-direction: column;
    justify-content: center;
  }
  p {
    font-size: 0.875em;
    letter-spacing: -0.01em;
    font-weight: 400;
  }
  .pagination_nav {
    margin: 20px 0;
    ul {
      @include flex-align-center;
      li {
        width: 35px;
        height: 35px;
        border-radius: $radius-round;
        border: 1px solid rgb(227, 227, 227);
        background: $color-white;
        color: #7e7e7e;
        @include flex-center;
        margin: 0 3px;
        cursor: pointer;
        font-size: 14px;
        &.active {
          color: $color-white;
          background: $color-primary;
        }
      }
    }
  }
}

.products_navigation_inner {
  .products_navigation_legend {
    @include flex-between;
    @include media-max(768px) {
      flex-direction: column;
      justify-content: center;
    }
    h1 {
      font-weight: 600;
      font-size: 20px;
      @include media-max(600px) {
        margin-bottom: 10px;
      }
    }
    p {
      font-size: 0.875em;
      letter-spacing: -0.01em;
      font-weight: 400;
    }
  }
  .products_navigation {
    background: #f5f5f5;
    border-radius: 5px;
    padding: 10px;
    margin: 10px 0;
    @include flex-between;
    @include media-max(350px) {
      flex-direction: column;
      align-items: flex-start;
    }
    .filters {
      display: none;
      @include media-max(1200px) {
        cursor: pointer;
        @include flex-align-center;
        font-size: 14px;
        opacity: 0.8;
        @include media-max(575px) {
          font-size: 12px;
        }
        @include hover {
          opacity: 1;
        }
        svg {
          margin-right: 4px;
          font-size: 20px;
          @include media-max(575px) {
            font-size: 16px;
          }
        }
      }
      @include media-max(350px) {
        margin-bottom: 10px;
      }
    }
    .icons {
      @include flex-align-center;
      svg {
        cursor: pointer;
        opacity: 0.3;
        @include hover {
          opacity: 1;
        }
        &.active {
          opacity: 1;
        }
      }
      @include media-max(1200px) {
        display: none;
      }
    }
    .dropdowns {
      @include flex-align-center;
      select {
        padding: 0.5rem 1.75rem 0.5rem 0.75rem;
        border-radius: 1.429em;
        color: #7e7e7e;
        border: 1px solid #e3e3e3;
        background: $color-white;
        margin: 0 10px;
        font-size: 13px;
        background: #fff url('../../images/arrows_sort.png') no-repeat right
          0.75rem center;
        background-size: 8px 10px;
        -webkit-appearance: none;
        @include media-max(1200px) {
          margin: 0;
          &:nth-child(2) {
            display: none;
          }
        }
        @include media-max(575px) {
          font-size: 11px;
        }
      }
    }
    .per_page {
      @include flex-align-center;
      input {
        color: #7e7e7e;
        width: 3.6em;
        text-align: center;
        padding: 0.201em 0.75em;
        border: 1px solid #e3e3e3;
        font-size: 13px;
        line-height: 2.2em;
        border-radius: 1.467em;
        margin-right: 5px;
      }
      span {
        color: #7e7e7e;
        font-size: 13px;
      }
      @include media-max(1200px) {
        display: none;
      }
    }
  }
}

@include media-min(1200px) {
  .products_grid_holder.list {
    grid-template-columns: 100%;
  }
}

@include media-min(1200px) {
  .products_grid_holder.small_list {
    grid-template-columns: 100%;
  }
}

.product_form_checkboxes {
  width: 170px;
}

.have-padding-top {
  padding-top: 5px;
}
.product_item_wrap {
  width: 100%;
  // @media screen and (max-width: 768px) {
  // width: 49%;
  // }
  overflow: visible;
  cursor: pointer;
  .product_item_holder {
    width: 100%;
    height: 100%;
    background: $color-white;
    border: 1px solid rgba(0, 0, 0, 0.102) !important;
    border-radius: 20px;

    position: relative;
    // box-shadow: 0 0 10px 0 rgba($color: $color-black, $alpha: 0.1);
    // @include hover {
    //   box-shadow: 0 0 20px 0 rgba($color: $color-black, $alpha: 0.2);
    // }
    .photo {
      width: 100%;
      height: 250px;
      @include flex-center;
      position: relative;
      @include media-max(768px) {
        padding-top: 25px;
        height: 140px;
      }
      .discount_icon {
        position: absolute;
        top: 20px;
        left: 20px;
        @include media-max(768px) {
          top: 10px;
          left: 10px;
        }
      }
      .catalog_sticker {
        @include media-min(768px) {
          width: 55px;
        }
      }
      .checkbox_holder {
        margin-right: 7px;
        @include flex-align-center;
        @include media-max(500px) {
          display: none;
        }
        .box {
          width: 15px;
          height: 15px;
          border-radius: 4px;
          border: 1px solid rgba($color: $color-black, $alpha: 0.4);
          margin-right: 5px;
        }
        label {
          font-size: 12px;
          color: #757575;
        }
      }
      .icons {
        @include flex-align-center;
        position: absolute;
        top: 20px;
        right: 20px;
        @include media-max(768px) {
          top: 10px;
          right: 10px;
        }
        svg {
          opacity: 0.7;
          @include transition;
          @include media-max(400px) {
            width: 20px;
            height: 20px;
            font-size: 20px;
          }
          &:hover {
            opacity: 1;
          }
        }
        .compare_icon {
          display: none;
          @include media-max(500px) {
            display: block;
          }
        }
        .favorite_icon {
          color: #333e48;
          &:hover {
            opacity: 0.3;
          }
        }
      }
      .product_img {
        max-width: 95%;
        max-height: 95%;
        @include media-max(768px) {
          width: auto;
          height: auto;
        }
      }
      .img_placeholder {
        width: 80px;
        height: 80px;
      }
    }
    .details {
      padding: 10px 15px;
      @include media-max(768px) {
        padding: 10px;
      }
      sup {
        text-decoration: none;
      }
      h2 {
        max-width: 250px;
        font-size: 14px;
        font-weight: 600;
        height: 60px;
        text-overflow: ellipsis;
        overflow: hidden;
        line-height: 1.5;
        color: black;
        margin-bottom: 12px;
        @include media-max(768px) {
          line-height: 1.3;
          font-size: 12px;
          height: 45px;
        }
      }
      .details_content {
        .discount_icon {
          display: none;
        }
      }
      .hidden {
        visibility: hidden;
      }
      .saving {
        color: #8bc34a;

        font-size: 15px;
        padding: 5px;
        display: inline;
        font-weight: 400;
        span {
          font-weight: 600;
        }
        @include media-max(768px) {
          font-size: 10px;
          padding: 0;
        }
      }
      .web_price_container {
        display: flex;
        color: black;
        sup {
          font-size: 12px;
          font-weight: 500;
          @include media-max(768px) {
            font-size: 8px;
          }
        }
      }
      .web_price_text {
        color: black;
        font-size: 13px;
        font-weight: 600;
        font-style: normal;
        @include media-max(768px) {
          font-size: 9px;
          margin-top: 12px;
        }
      }

      .web_price {
        color: black;
        font-size: 20px;
        text-decoration: line-through;
        @include media-max(768px) {
          font-size: 12px;
          height: 17px;
          //margin: 15px 0 7px 3px;
          font-weight: bold;
        }

        span {
          font-size: 13px;
          font-weight: 400;
          @include media-max(420px) {
            font-size: 10px;
            font-weight: 600;
          }
        }
      }
      // .flex {
      //   display: grid;
      //   grid-template-columns: 1fr 40px;
      //   align-items: center;
      //   padding-bottom: 2px;
      //   @include media-max(768px) {
      //     grid-template-columns: 1fr 29px;
      //     grid-gap: 0 5px;
      //   }
      //   @include media-max(350px) {
      //     grid-template-columns: 1fr 28px;
      //   }
      //   @include media-max(450px) {
      //     margin-top: 5px;
      //   }
      .price {
        font-weight: 600;
        font-size: 36px;

        color: black;
        @include media-max(768px) {
          font-size: 22px;
          font-weight: 600;
        }
        // @include media-max(380px) {
        //   font-size: 15px;
        //   line-height: 1;
        // }
        sup {
          font-size: 15px;
        }
        span {
          font-size: 15px;
          font-weight: 600;
          color: #313131;
          @include media-max(420px) {
            font-size: 12px;
          }
          @include media-max(380px) {
            font-size: 10px;
          }
        }
      }
      .add_to_cart {
        width: 100%;
        padding: 20px;
        margin-top: 20px;

        border-radius: 90px;
        background: #7367f0;
        color: white;

        &:hover {
          background: #4537db;
        }
        @include media-max(768px) {
          font-size: 10px;
          padding: 12px;
        }
      }
      .info_text {
        margin-top: 10px;
        color: black;
        font-size: 12px;
        font-weight: 500;
        @include media-max(768px) {
          font-size: 8px;
        }
      }
      .icon_button {
        width: 40px;
        height: 40px;
        color: $color-white;
        background: #ddd;
        @include hover {
          background: $color-primary;
        }
        @include media-max(768px) {
          width: 29px;
          height: 29px;
          svg {
            font-size: 20px;
          }
        }
        @include media-max(350px) {
          width: 28px;
          height: 28px;
          margin-top: 4px;
        }
      }

      //}
    }
  }
}

.product_categories_page {
  .products_grid_holder {
    @include media-min(1200px) {
      &.list {
        grid-template-columns: 100%;
        grid-gap: 20px 0;
        .product_item_wrap {
          .label-wrapper {
            position: absolute;
          }
          .product_item_holder {
            display: grid;
            grid-template-columns: 250px 1fr;
            grid-gap: 0 20px;
            .photo {
              height: 200px;
              margin-left: 30px;

              padding: 10px 0;
              position: static;
              .product_img {
                max-width: 85%;
                max-height: 85%;

                object-fit: contain;
                width: auto;
                height: auto;
              }
              .discount_icon {
                display: none;
              }
            }
            .details {
              display: grid;
              grid-template-columns: 1fr 250px;
              grid-gap: 0 20px;
              align-items: center;
              h2 {
                height: auto;
                line-height: 1.4;
                padding: 0 20px;
              }
              .details_content {
                text-align: center;
                @include flex-column-center;
                width: 100%;
                margin-top: 25px;
                .discount_icon {
                  display: block;
                  margin-bottom: 10px;
                }
                .web_price {
                  margin: 0 0 5px 0;
                }
                .flex {
                  width: 100%;
                  @include flex-column-center;
                  .price {
                    margin: 5px 0 10px 0;
                  }
                }
                .icon_button {
                  display: none;
                }
                .hidden_button {
                  margin-top: 5px;
                  @include flex-center;
                  width: 70%;
                  height: 33px;
                  border-radius: 30px;
                  background: $color-primary;
                  color: $color-white;
                  font-size: 12px;
                  font-weight: bold;
                  @include hover {
                    background: $color-black;
                  }
                  svg {
                    margin-right: 5px;
                    font-size: 18px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
